export const environment = {
	production: false,
	sandbox: false,
	appUrl: "https://qa.portal.deliverysolutions.co/",
	serverUrl: "https://qa.customer-api.deliverysolutions.co/",
	streamServiceUrl: "https://qa.streaming.deliverysolutions.co/stream/v2",
	googleMapsAPIKey: "",
	googleTagmanagerID: "GTM-MK24V68",
	awsConfig: {
		fileStorage: {
			bucket: "https://qa.files.deliverysolutions.co",
		},
	},
	serviceWorkerEnabled: true,
	checkUpdateInterval: 600000,
	env: "qa",
	googleMapsApiKeyDecryptionPassphrase: "vMrpNRaRAL2RuCF",
	userPilotAuthKey: "NX-d12a048a",
};
